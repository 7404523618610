<template>
  <v-container>
    <h2 class="tw-font-figtree tw-text-25 tw-font-semibold tw-text-charcoal">
      Add Details
    </h2>
    <p class="tw-text-light-grey tw-mb-11">
      Not sure yet? Skip now & finalize later.
    </p>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="createTrip">
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Trip name"
              rules="required|max:30"
            >
              <j-text-field
                v-model="title"
                label="Trip Name*"
                required
                placeholder="Name of Trip"
                :hide-details="errors.length === 0"
                :error-messages="errors"
                background-color="#f5f5f5"
                prepend-inner-icon="mdi-airplane"
                :maxlength="30"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <j-text-field
              v-model="destination"
              label="Destination (Not sure? Skip for Now)"
              placeholder="Enter Destination"
              background-color="#f5f5f5"
              prepend-inner-icon="mdi-map-marker"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="j-text-field-label"
              >Dates (Not sure? Skip for Now)</span
            >
            <v-expansion-panels
              v-model="isOpen"
              class="tw-rounded-md"
              ref="datePanel"
            >
              <v-expansion-panel ref="datesPanel">
                <v-expansion-panel-header>
                  <div
                    class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                  >
                    <v-icon>mdi-calendar-blank</v-icon>
                    <div
                      class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                    >
                      <span v-if="dates">{{
                        getFormattedDateRange(dates[0], dates[1])
                      }}</span>
                      <span v-else>Choose a date</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-date-picker
                    class="tw-py-4"
                    no-title
                    color="secondary"
                    v-model="dates"
                    range
                    :min="minDate"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="dates = []"
                      >Reset Dates</v-btn
                    >
                  </v-date-picker>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-btn
              block
              x-large
              rounded
              :loading="isCreateTripPending"
              :disabled="invalid || isCreateTripPending"
              color="secondary"
              type="submit"
              class="tw-tracking-normal"
              >Next</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import feathersClient from "../../feathers-client.js";
import { DateTime } from "luxon";
export default {
  name: "CreateATripForm",
  data() {
    return {
      title: null,
      dates: [],
      destination: null,
      isOpen: null,
      minDate: DateTime.local().toISODate(),
      trips: [],
    };
  },
  watch: {
    dates(val) {
      if (val[0] && val[1]) {
        let temp = [...val];
        if (new Date(val[0]) > new Date(val[1])) {
          this.dates[0] = temp[1];
          this.dates[1] = temp[0];
        }
      }
    },
  },
  mixins: [FormattedDateRange],
  computed: {
    isCreateTripPending() {
      return this.$store.state.trip.isCreatePending;
    },
  },
  methods: {
    async fetchAndLogUserTrips() {
      try {
        const userId = this.$store.state.auth.user.id;

        const response = await feathersClient.service("users").get(userId, {
          query: {
            include: `trip_invite,trip`,
          },
        });
        this.trips = response.trips;
      } catch (error) {
        console.error("Error fetching user trips:", error);
      }
    },
    async createTrip() {
      await this.$store
        .dispatch("trip/create", {
          title: this.title,
          destination: this.destination,
          startDate: this.dates[0]
            ? DateTime.fromFormat(this.dates[0], "yyyy-MM-dd", {
                setZone: true,
              })
                .startOf("day")
                .toISODate()
            : null,
          endDate: this.dates[1]
            ? DateTime.fromFormat(this.dates[1], "yyyy-MM-dd", {
                setZone: true,
              })
                .endOf("day")
                .toISODate()
            : null,
        })
        .then((res) => {
          //check trips length for dataLayer event if it is a first trip or more
          const now = DateTime.now();
          const start = DateTime.fromISO(this.$store.state.auth.user.createdAt);
          const diff = start.diff(now, "days").toObject();
          const count = Math.abs(Math.round(diff.days));
          let newUser = false;
          let hasDestination = false;
          let hasDates = false;
          let event = "create_first_trip";
          if (count <= 7) {
            newUser = true;
          }

          if (res.destinations) hasDestination = true;

          if (res.startDate && res.endDate) hasDates = true;
          if (this.trips.length > 0) {
            event = "create_trip";
          }

          window.dataLayer.push({
            event: event,
            uuid: this.$store.state.auth.user.uuid,
            signup_date: DateTime.fromISO(
              this.$store.state.auth.user.createdAt,
            ).toISO(),
            new_user: newUser,
            verified_user: this.$store.state.auth.user.isEmailVerified,
            sign_up_trip_id: this.$store.state.auth.user.signupTripId,
            trip_id: res.id,
            owner_id: res.ownerId,
            has_dates: hasDates,
            has_destination: hasDestination,
          });

          this.$router.push({
            name: "TripCreatedConfirmation",
            params: { id: res.id },
          });
        });
    },
  },
  beforeMount() {
    this.fetchAndLogUserTrips();
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
  @apply tw-shadow-custom;
}
.v-expansion-panel-header--active .j-panel-header .v-icon {
  @apply tw-text-dark-green;
}
</style>
