<template>
  <div class="trips-view tw-mb-10">
    <create-a-trip-form />
  </div>
</template>

<script>
import CreateATripForm from "@/components/trip/CreateATripForm";

export default {
  name: "TripCreateView",

  components: {
    CreateATripForm,
  },

  mounted() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Create A Trip",
      bgColor: "#E6FFA1",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true,
    });
  },
};
</script>

<style lang="scss"></style>
